var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { attrs: { id: "order-details-form" } }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.firstname")))
          ]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { name: "firstname" },
            model: {
              value: _vm.value.firstname,
              callback: function($$v) {
                _vm.$set(_vm.value, "firstname", $$v)
              },
              expression: "value.firstname"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.order_info.infobox.lastname")))
          ]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { name: "lastname" },
            model: {
              value: _vm.value.lastname,
              callback: function($$v) {
                _vm.$set(_vm.value, "lastname", $$v)
              },
              expression: "value.lastname"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "vx-col 1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit(_vm.value)
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")) + " ")]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }