<template>
  <main id="order-details-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t('shops.order_info.infobox.firstname') }}</label>
        <vs-input
          v-model="value.firstname"
          class="w-full"
          name="firstname"
        />
      </div>
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t('shops.order_info.infobox.lastname') }}</label>
        <vs-input
          v-model="value.lastname"
          class="w-full"
          name="lastname"
        />
      </div>
    </div>
    <div class="vx-col 1/3">
      <vs-button
        @click="validateAndSubmit(value)"
        class="mt-base"
        type="filled"
        >{{ $t("shops.save") }}
      </vs-button>
    </div>
  </main>
</template>

<script>
const defaultOrderDetail = () => ({
  id: 0,
  person_id: 0,
  firstname: "",
  lastname: ""
});

export default {
  name: "order-details-form",

  props: {
    value: { type: Object, default: () => defaultOrderDetail() }
  },

  data() {
    return {

    }
  },

  computed: {

  },

  methods: {
    validateAndSubmit(value) {
      this.$emit("submit", value);
    }
  }
}
</script>

<style>

</style>
