<template>
  <vx-card class="mb-base mt-10 no-shadow">
    <div class="email-config-form">

      <div class="vx-row mb-6">

        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.host") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.host"
            name="mail_host"
            v-validate="'required'"
            :danger="errorHost && errors.has('mail_host')"
            :danger-text="$t('shops.errors.host_is_required')"
          />
        </div>

      </div>
      <div class="vx-row mb-6">

        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.username") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.userName"
            name="mail_username"
            v-validate="'required'"
            :danger="errorUserName && errors.has('mail_username')"
            :danger-text="$t('shops.errors.user_name_is_required')"
          />
        </div>

        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.password") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.password"
            name="mail_password"
            v-validate="'required'"
            :danger="errorPassword && errors.has('shops.mail_password')"
            :danger-text="$t('shops.errors.password_is_required')"
          />
        </div>

        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.port") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.port"
            name="mail_port"
            v-validate="'required'"
            :danger="errorPort && errors.has('shops.mail_port')"
            :danger-text="$t('shops.errors.port_is_required')"
          />
        </div>

        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.encryption") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.encryption"
            name="mail_encryption"
          />
        </div>

        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.from_address") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.fromAddress"
            name="mail_from_address"
            v-validate="'required'"
            :danger="errorFromAddress && errors.has('mail_from_address')"
            :danger-text="$t('shops.errors.from_address_is_required')"
          />
        </div>
        <div class="vx-col w-1/3">
          <label class="vs-input--label">{{ $t("shops.from_name") }}</label>
          <vs-input
            class="w-full"
            v-model="formData.fromName"
            name="mail_from_name"
            v-validate="'required'"
            :danger="errorFromName && errors.has('mail_from_name')"
            :danger-text="$t('shops.errors.from_name_is_required')"
          />
        </div>
      </div>


      <div class="vx-col">
        <vs-button @click="onFormSubmit(formData)" class="mt-4" type="filled">{{ $t("shops.save") }}</vs-button>
      </div>
      <div class="vx-col mt-12">
        <label class="vs-input--label block">{{$t('shops.email_address')}}</label>
        <vs-input
            v-model="testMail"
            class="inline-block"
            name="mail_test"
          />
        <vs-button @click="sendTest(testMail)" type="filled" class="ml-4">{{$t('shops.send')}}</vs-button>
      </div>
    </div>

  </vx-card>
</template>

<script>
import notifications from "@/modules/Shared/Mixins/notifications.js";
import { mapGetters, mapActions } from "vuex";
import i18n from "@/i18n";

const defaultEmailConfig = () => ({
  host: "",
  port: "",
  userName: "",
  password: "",
  encryption: "",
  fromAddress: "",
  fromName: ""
});

export default {
  name: "EmailConfiguration",

  mixins: [notifications],

  data() {
    return {
      emailData: {},
      formData: defaultEmailConfig(),
      testMail: "",
      errorHost: false,
      errorUserName: false,
      errorPassword: false,
      errorPort: false,
      errorEncryption: false,
      errorFromAddress: false,
      errorFromName: false
    };
  },

  computed: {
    ...mapGetters("shops/resorts", {
      resortEmailConfig: "resortEmailConfig"
    }),
    ...mapGetters("auth", {
      drm: "drm",
    }),
  },

  methods: {
    ...mapActions("shops/resorts", {
      loadResortEmailConfig: "loadResortEmailConfig",
      setResortEmailConfig: "setResortEmailConfig"
    }),
    ...mapActions("shops/mail", ["sendTestMail"]),
    async onFormSubmit(emailConfig) {
      this.$validator.validateAll().then((result) => {
        if(result) {
          try {
            this.setResortEmailConfig(emailConfig);
            this.notifySuccess(i18n.t("shops.success"), i18n.t('shops.email_settings_have_been_saved_successfully'));
          } catch (error) {
            this.notifyError(i18n.t("shops.errors.failed_to_save_email_configuration"), error);
          }
        } else {
          this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.email_validation_failed"));
          if (!emailConfig.host) this.errorHost = true;
          if (!emailConfig.userName) this.errorUserName = true;
          if (!emailConfig.password) this.errorPassword = true;
          if (!emailConfig.port) this.errorPort = true;
          if (!emailConfig.fromAddress) this.errorFromAddress = true;
          if (!emailConfig.fromName) this.errorFromName = true;
        }
      });
    },
    async sendTest(mail){
        try{
          const response = await this.sendTestMail({to: mail});
          if(response)
            this.notifySuccess(i18n.t("shops.success"), i18n.t('shops.test_email_sent_successfully'));
          else
            this.notifyError(i18n.t("shops.errors.failed_to_send_email"));
        }
        catch(error){
          this.notifyError(i18n.t("shops.errors.failed_to_send_email"), error);
        }
    }
  },

  async created() {
    this.$vs.loading();

    await this.loadResortEmailConfig(this.$route.params.uuid).then(() => {
      this.formData = this.resortEmailConfig;
      this.formData.resort_id = this.$route.params.uuid;
    })
    .finally(() => {
      this.$vs.loading.close();
    });
  },
};
</script>

<style>
</style>
