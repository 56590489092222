var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vx-card", { staticClass: "mb-base mt-10 no-shadow" }, [
    _c("div", { staticClass: "email-config-form" }, [
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.host")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "mail_host",
                danger: _vm.errorHost && _vm.errors.has("mail_host"),
                "danger-text": _vm.$t("shops.errors.host_is_required")
              },
              model: {
                value: _vm.formData.host,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "host", $$v)
                },
                expression: "formData.host"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.username")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "mail_username",
                danger: _vm.errorUserName && _vm.errors.has("mail_username"),
                "danger-text": _vm.$t("shops.errors.user_name_is_required")
              },
              model: {
                value: _vm.formData.userName,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "userName", $$v)
                },
                expression: "formData.userName"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.password")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "mail_password",
                danger:
                  _vm.errorPassword && _vm.errors.has("shops.mail_password"),
                "danger-text": _vm.$t("shops.errors.password_is_required")
              },
              model: {
                value: _vm.formData.password,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "password", $$v)
                },
                expression: "formData.password"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.port")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "mail_port",
                danger: _vm.errorPort && _vm.errors.has("shops.mail_port"),
                "danger-text": _vm.$t("shops.errors.port_is_required")
              },
              model: {
                value: _vm.formData.port,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "port", $$v)
                },
                expression: "formData.port"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.encryption")))
            ]),
            _c("vs-input", {
              staticClass: "w-full",
              attrs: { name: "mail_encryption" },
              model: {
                value: _vm.formData.encryption,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "encryption", $$v)
                },
                expression: "formData.encryption"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.from_address")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "mail_from_address",
                danger:
                  _vm.errorFromAddress && _vm.errors.has("mail_from_address"),
                "danger-text": _vm.$t("shops.errors.from_address_is_required")
              },
              model: {
                value: _vm.formData.fromAddress,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "fromAddress", $$v)
                },
                expression: "formData.fromAddress"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/3" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.from_name")))
            ]),
            _c("vs-input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'"
                }
              ],
              staticClass: "w-full",
              attrs: {
                name: "mail_from_name",
                danger: _vm.errorFromName && _vm.errors.has("mail_from_name"),
                "danger-text": _vm.$t("shops.errors.from_name_is_required")
              },
              model: {
                value: _vm.formData.fromName,
                callback: function($$v) {
                  _vm.$set(_vm.formData, "fromName", $$v)
                },
                expression: "formData.fromName"
              }
            })
          ],
          1
        )
      ]),
      _c(
        "div",
        { staticClass: "vx-col" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mt-4",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.onFormSubmit(_vm.formData)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.save")))]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-col mt-12" },
        [
          _c("label", { staticClass: "vs-input--label block" }, [
            _vm._v(_vm._s(_vm.$t("shops.email_address")))
          ]),
          _c("vs-input", {
            staticClass: "inline-block",
            attrs: { name: "mail_test" },
            model: {
              value: _vm.testMail,
              callback: function($$v) {
                _vm.testMail = $$v
              },
              expression: "testMail"
            }
          }),
          _c(
            "vs-button",
            {
              staticClass: "ml-4",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.sendTest(_vm.testMail)
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.send")))]
          )
        ],
        1
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }