var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vx-card",
        { staticClass: "mb-base no-shadow" },
        [
          _vm.showForm
            ? [
                _c("vs-button", { on: { click: _vm.onCreate } }, [
                  _vm._v(_vm._s(_vm.$t("Back")))
                ])
              ]
            : _vm._e(),
          !_vm.showForm
            ? [
                _c(
                  "div",
                  { staticClass: "mb-base" },
                  [
                    _c("data-table", {
                      staticClass: "datatableTickets",
                      attrs: { data: _vm.tableData, options: _vm.tableOptions },
                      on: {
                        create: _vm.onCreate,
                        remove: _vm.onRemove,
                        update: _vm.loadUpdate,
                        sort: _vm.onSort,
                        search: _vm.onSearch,
                        "change-page": _vm.onTablePageChange,
                        "per-page-change": _vm.onPerPageChange,
                        "date-range-search": _vm.onDateRangeSearch,
                        "date-range-clear": _vm.onDateRangeClear
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "actions",
                            fn: function(ref) {
                              var data = ref.data
                              return [
                                data.status_id === _vm.STATUS_CONFIRMED &&
                                data.external_id !== null
                                  ? _c("vs-button", {
                                      ref: "cancelButton",
                                      staticClass: "float-left mr-1",
                                      attrs: {
                                        icon: "cancel",
                                        size: "large",
                                        title: _vm.$t("Cancel")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.openPopup(data)
                                        }
                                      }
                                    })
                                  : _c("vs-button", {
                                      ref: "cancelButton",
                                      staticClass: "float-left mr-1",
                                      attrs: {
                                        icon: "cancel",
                                        size: "large",
                                        title: _vm.$t("Cancel"),
                                        disabled: ""
                                      }
                                    })
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        712114523
                      )
                    })
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.showForm
            ? _c("teamplate", [
                _c(
                  "div",
                  { staticClass: "mb-base mt-10" },
                  [
                    _c("order-details-form", {
                      on: { submit: _vm.onUpdate },
                      model: {
                        value: _vm.formData,
                        callback: function($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData"
                      }
                    })
                  ],
                  1
                )
              ])
            : _vm._e(),
          _c(
            "vs-popup",
            {
              attrs: {
                classContent: "popup-example",
                title: "",
                active: _vm.popupRemove
              },
              on: {
                "update:active": function($event) {
                  _vm.popupRemove = $event
                }
              }
            },
            [
              _c("p", { staticClass: "mb-5" }, [
                _vm._v(_vm._s(_vm.$t("shops.cancel_order.message")))
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "float-right",
                  attrs: { size: "large" },
                  on: {
                    click: function($event) {
                      _vm.popupRemove = false
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Cancel")) + " ")]
              ),
              _c(
                "vs-button",
                {
                  staticClass: "float-right mr-1",
                  attrs: { size: "large", type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.onCancelClicked(_vm.dataSelected)
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("Accept")) + " ")]
              )
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }