<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <template v-if="showForm">
        <vs-button @click="onCreate">{{ $t("Back") }}</vs-button>
      </template>

      <template v-if="!showForm">
        <div class="mb-base">
          <data-table
            class="datatableTickets"
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove"
            @update="loadUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
            @date-range-search="onDateRangeSearch"
            @date-range-clear="onDateRangeClear"
          >
            <template slot="actions" slot-scope="{ data }">
              <vs-button
                v-if="data.status_id === STATUS_CONFIRMED && data.external_id !== null"
                ref="cancelButton"
                @click="openPopup(data)"
                class="float-left mr-1"
                icon="cancel"
                size="large"
                :title="$t('Cancel')"
              ></vs-button>
              <vs-button
                v-else
                class="float-left mr-1"
                ref="cancelButton"
                icon="cancel"
                size="large"
                :title="$t('Cancel')"
                disabled
              ></vs-button>
            </template>
          </data-table>
        </div>
      </template>

      <teamplate v-if="showForm">
        <div class="mb-base mt-10">
          <order-details-form v-model="formData" @submit="onUpdate" />
        </div>
      </teamplate>

      <vs-popup
        classContent="popup-example"
        title=""
        :active.sync="popupRemove">
          <p class="mb-5">{{$t('shops.cancel_order.message')}}</p>
          <vs-button
          @click="popupRemove=false"
          class="float-right"
          size="large"
          >
          {{$t('Cancel')}}
          </vs-button>
          <vs-button
          @click="onCancelClicked(dataSelected)"
          class="float-right mr-1"
          size="large"
          type="border"
          >
          {{$t('Accept')}}
          </vs-button>
      </vs-popup>
    </vx-card>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable.vue";
import OrderDetailsForm from "@/modules/Shops/Pages/forms/OrderDetailsForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";
import { mapGetters, mapActions } from "vuex";
import { shopapi } from "@/axios";
import i18n from "@/i18n";

const apiVersion = process.env.VUE_APP_SHOPS_API_GATEWAY_VERSION;

export default {
  name: "tickets",

  components: {
    DataTable,
    OrderDetailsForm,
  },

  mixins: [notifications, table_functions],

  props: {
    onlyForm: { type: Boolean, default: false },
  },

  data() {
    return {
      showForm: false,
      formData: {},
      defaultSort: "-created_at",
      exportPayload: {},
      popupRemove: false,
      proceed: false,
      dataSelected: {},
      STATUS_CONFIRMED: 4
    };
  },

  created() {
    this.formData = this.default()
    this.exportPayload.sort = this.defaultSort;
    
    this.$store.commit("shops/orderDetails/RESET_RESOURCE");
  },

  computed: {
    ...mapGetters("shops/order_details", {
      orderDetailsList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      ticketById: "getById",
      orderDetailIndexById: "getIndexById",
    }),

    ...mapGetters(['AppActiveUser']),

    tableData() {
      return this.orderDetailsList.map(this.orderDetailsToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: false,
          update: true,
          delete: false,
          custom: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      }
    },
  },

  methods: {
    ...mapActions("shops/order_details", {
      loadData: "load",
      updateData: "update",
      removeData: "remove",
    }),

    openPopup(data) {
      this.dataSelected = data;
      this.popupRemove = true;
    },

    orderDetailsToTableData(orderDetail) {
      return {
        id: orderDetail.id,
        external_id: orderDetail.external_id,
        status_id: orderDetail.status.id,
        firstname: orderDetail.firstname,
        lastname: orderDetail.lastname,
        email: orderDetail.client_email,
        phone: orderDetail.client_phone || "---",
        product: orderDetail.product_name,
        category: orderDetail.participant_name,
        keycard: orderDetail.keycard || "---",
        date_of_use: orderDetail.date,
        reference: orderDetail.reference,
        total_price_tax: orderDetail.total_price_tax,
        status: orderDetail.status.description,
        date_of_purchase: orderDetail.created_at
      };
    },

    async loadUpdate(ticket) {
      this.showForm = true;
      this.formData = Object.assign(
        {},
        this.formData,
        this.ticketById(ticket.id)
      );
    },

    async onUpdate(orderDetail) {
      this.$vs.loading();
      try {
        await this.updateData(orderDetail);
      }catch(e) {
        console.warn(e);
        this.$vs.loading.close();
      } finally {
        this.showForm = !this.showForm
        this.notifySuccess(
          i18n.t("shops.updated"),
          i18n.t("shops.order_detail") + " " + orderDetail.reference + " " + i18n.t("shops.correctly_updated")
        );
        this.$vs.loading.close();
      }
    },

    async onCancelClicked(data) {
      this.$vs.loading();
      try {
        const storeOrderDetail = this.$store.getters["shops/order_details/getFirst"](x => x.reference === data.reference);
        const response = await shopapi.post(`/api/${apiVersion}/resorts/${this.$route.params.uuid}/order_details/${storeOrderDetail.id}/cancel`, {username: this.AppActiveUser.username});

        if (response.status === 200) {
          this.notifySuccess(i18n.t("shops.success"), i18n.t("shops.the_ticket_was_successfuly_canceled"));
          const index = this.orderDetailIndexById(storeOrderDetail.id);

          if (index !== -1) {
            this.$store.commit("shops/order_details/SET_BY_INDEX", { index, resource: response.data }, { root: true });
          } else {
            console.warn(i18n.t("shops.failed_to_update_ticket_in_store"), index);
          }
        } else {
          this.notifyError(
            i18n.t("shops.error"),
            i18n.t("shops.there_was_an_error_while_trying_to_cancel_please_try_again_later")
          );
        }
      } catch (e) {
        console.warn(e);
      } finally {
        this.popupRemove = false;
        this.$vs.loading.close();
      }
    },

    default() {
      return {
        firstname: "",
        lastname: "",
        reference: "",
        client: "",
        email: "",
        phone: "",
        keycard: "",
        date_of_use: "",
        product: "",
        category: "",
        quantity: 0,
        unit_price: 0,
        total: 0,
        status: "",
        date_of_purchase: "",
      }
    }
  },
};
</script>

<style>
  .datatableTickets th:nth-child(1) {
    display: none;
  }
  .datatableTickets td:nth-child(1) {
    display: none;
  }
  .datatableTickets th:nth-child(2) {
    display: none;
  }
  .datatableTickets td:nth-child(2) {
    display: none;
  }
  .datatableTickets th:nth-child(3) {
      display: none;
    }
  .datatableTickets td:nth-child(3) {
    display: none;
  }
</style>
